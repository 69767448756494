import * as React from "react";
import { Link } from "gatsby";

// Import layout
import Layout from "../layouts/main";

// Import components
import MetaData from "../components/MetaData";

// Import styles
import "./styles/error.scss";

// Import assets
import image from "../assets/images/og-image.jpg";

// Bootstrap imports
import { Button, Container } from "react-bootstrap";

const IndexPage = () => {
  return (
    <Layout>
      <MetaData
        title="404 Error | Flint"
        description="Oops! It seems the page you're looking for does not exist."
        image={`https://flint-fire-starter.netlify.app${image}`}
      />
      <div className="error">
        <Container>
          <div className="error-intro">
            <h1 className="shout">404 Error: Page Not Found</h1>
          </div>
          <div className="error-body">
            <p className="talk">
              Oops! It seems the page you're looking for does not exist.
            </p>
            <Link to="/">
              <Button variant="accent">Back to home</Button>
            </Link>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default IndexPage;
